import { SxProps, Theme } from "@mui/material";

import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import RoofingIcon from "@mui/icons-material/Roofing";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";


type IIcon = {
  fontSize?: "small" | "inherit" | "large" | "medium" | undefined;
  sx?: SxProps<Theme> | undefined;
};

export const IconContentPaste = (props: IIcon) => {
  return <ContentPasteIcon {...props} />;
};

export const IconLightMode = (props: IIcon) => {
  return <LightModeIcon {...props} />;
};

export const IconContentCopy = (props: IIcon) => {
  return <ContentCopyIcon {...props} />;
};

export const IconDarkMode = (props: IIcon) => {
  return <DarkModeIcon {...props} />;
};

export const IconNext = (props: IIcon) => {
  return <NavigateNextIcon {...props} />;
};
export const IconPrev = (props: IIcon) => {
  return <NavigateBeforeIcon {...props} />;
};

export const IconDone = (props: IIcon) => {
  return <DoneIcon {...props} />;
};

export const IconClose = (props: IIcon) => {
  return <CloseIcon {...props} />;
};

export const IconHome = (props: IIcon) => {
  return <RoofingIcon {...props} />;
};
