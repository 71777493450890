export type states = "authentification" | "chooseTest" | "quiz" | "showResults";

export default class StateConsts {
  static readonly AUTHENTIFICATON_STATE = "authentificationState";
  static readonly CHOOSE_TEST_STATE = "chooseTestState";
  static readonly QUIZ_STATE = "quizState";
  static readonly SHOW_RESULT_STATE = "showResultState";
  static readonly TIMEOUT_STATE = "timeoutState";
  static readonly QUIZ_RECAP = "quizRecap";
}
