import * as React from "react";
import { useDispatch } from "react-redux";
import { switchToDark, switchToLight } from "../store/theme";
import { IconDarkMode, IconLightMode } from "../assets/Icons";
import { IconButton, Tooltip, useTheme } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useThemeSelectorIsLight } from "../hooks/store/useThemeSelector";

export default function ThemeSwitcher() {
  const dispatch = useDispatch();
  const isLightTheme = useThemeSelectorIsLight();
  const [checked, setChecked] = React.useState(isLightTheme);
  const theme = useTheme();

  const color = alpha(theme.palette.primary.main, 0.03);

  const handleChange = () => {
    setChecked(!checked);
  };

  React.useEffect(() => {
    dispatch(checked ? switchToLight() : switchToDark());
  }, [checked]);

  return (
    <Tooltip title={checked ? "Turn off the light" : "Turn on the light"}>
      <IconButton
        disableTouchRipple
        onClick={handleChange}
        color="primary"
        edge="start"
        sx={{
          border: "1px solid",
          borderColor: color,
          borderRadius: 2,
          backgroundColor: color,
        }}
      >
        {checked ? (
          <>
            <IconDarkMode fontSize="small" />
          </>
        ) : (
          <IconLightMode fontSize="small" />
        )}
      </IconButton>
    </Tooltip>
  );
}
