import { Grid, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import Widget from "../../../components/Widget";
import {
  selectCorrectByUser,
  selectCurrentQuestionNo,
  selectQuizName,
  selectTotalQuestions,
  selectWrongByUser,
} from "../../../hooks/store/useQuizSelector";

export default function TopInfo() {
  const correctAnswersCount = useSelector(selectCorrectByUser);
  const wrongAnswersCount = useSelector(selectWrongByUser);
  const totalQuestions = useSelector(selectTotalQuestions);
  const currentQuestionNo = useSelector(selectCurrentQuestionNo);
  const quizName = useSelector(selectQuizName);

  return (
    <>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item sm={12} md={6} alignItems={"center"}>
          <Typography variant={"h6"} fontWeight={"bold"}>
            {quizName}
          </Typography>
        </Grid>
        <Grid item sm={12} md={6}>
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Grid item>
              <Widget
                title="Correct"
                subtitle={correctAnswersCount + ""}
                secondaryColor={"success.main"}
                tooltip={"Number of correct answers"}
              />
            </Grid>
            <Grid item>
              <Widget
                title="Wrong"
                subtitle={wrongAnswersCount + ""}
                secondaryColor={"warning.main"}
                tooltip={"Number of wrong answers"}
              />
            </Grid>
            <Grid item>
              <Widget
                title="Question"
                subtitle={`${currentQuestionNo}/${totalQuestions}`}
                secondaryColor={"info.main"}
                tooltip={"Current question / total questions"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
