import {
  AlertColor,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IconNext } from "../../assets/Icons";
import Ad from "../../components/Ad";
import Div from "../../components/Div";
import GroupFormSelector from "../../components/GroupFormSelector";
import Section from "../../components/Section";
import Widget from "../../components/Widget";
import { setNoOfQuestionsRequested, setQuizNameAndId } from "../../store/quiz";
import { quizState } from "../../store/state";
import { useAllQuizzes } from "../../hooks/query/useAllQuizzes";
import { useToken } from "../../hooks/query/useToken";
import { Keys } from "../../common/Keys";
import BottomInfo from "../quiz/components/BottomInfo";
import ButtonContained from "../../components/ButtonContained";
interface IChooseTest {}
export default function ChooseTest(props: IChooseTest) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [colorSeverity, setColorSeverity] = useState<AlertColor>();

  const localStorageToken = localStorage.getItem(Keys.TOKEN);
  const {
    token,
    isLoading: tokenIsLoading,
    isError: tokenIsError,
    checkIsValidToken,
  } = useToken();

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [info, setInfo] = useState("Please select an option to continue!");
  const [tokenInfo, setTokenInfo] = useState("");

  const { data: quizzes, isLoading, isError } = useAllQuizzes();
  useEffect(() => {
    localStorageToken && checkIsValidToken(localStorageToken);
  }, []);
  useEffect(() => {
    quizzes?.payload &&
      selectedIndex !== null &&
      setInfo(`You selected ${quizzes.payload[selectedIndex].name}`);
  }, [selectedIndex]);

  useEffect(() => {
    const expirationDate = token?.payload?.validUntil
      ? new Date(token?.payload?.validUntil)
      : new Date(token?.payload?.expiresAt || ""); // assuming 'token.expiration' contains the expiration date
    const now = new Date();
    const tokenInfo = timeBetween(now, expirationDate);
    setTokenInfo(`Your token will expire in ${tokenInfo}.`);
  }, [token]);

  const timeBetween = (startDate: Date, endDate: Date) => {
    const difference = endDate.getTime() - startDate.getTime(); // difference in milliseconds
    const daysDifference = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hoursDifference = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutesDifference = Math.floor(
      (difference % (1000 * 60 * 60)) / (1000 * 60)
    );

    if (daysDifference > 0) {
      setColorSeverity("info");
      return `${daysDifference} day${daysDifference === 1 ? "" : "s"}`;
    } else if (hoursDifference > 0) {
      setColorSeverity("warning");
      return `${hoursDifference} hour${hoursDifference === 1 ? "" : "s"}`;
    } else if (minutesDifference > 0) {
      setColorSeverity("error");
      return `${minutesDifference} minute${minutesDifference === 1 ? "" : "s"}`;
    } else {
      setColorSeverity("error");
      return `less than 1 minute`;
    }
  };

  const getElements = (): JSX.Element[] => {
    return quizzes?.payload
      ? quizzes.payload.map((materie, index) => (
          <Div
            key={index}
            width={150}
            height={150}
            contentCentered
            centered
            sx={{ bgcolor: "transparent" }}
          >
            <Widget title={materie.name} direction="column" />
          </Div>
        ))
      : [];
  };

  const handleTest = (isFull:Boolean) => {
    if (quizzes?.payload && selectedIndex !== null) {

      isFull && dispatch(setNoOfQuestionsRequested(Keys.NO_OF_QUESTIONS_FOR_FULL));

      dispatch(
        setQuizNameAndId({
          id: quizzes.payload[selectedIndex].id,
          name: quizzes.payload[selectedIndex].name,
        })
      );
      dispatch(quizState());
    }
  };

  const getInfo = () => {
    return (
      <Div
        contentCentered
        sx={{
          flexDirection: "column",
          gap: 2,
          p: 0,
          borderRadius: 0,
        }}
      >
        <Typography variant={"h6"}>{info}</Typography>

        <Div sx={{
          p:0,
          display:"flex",

          gap:1
        }}>
          <ButtonContained
            disabled={selectedIndex === null}
            onClick={() => handleTest(true)}
            aftIcon={<IconNext />}
          >
            <Typography variant={"h5"}>FULL</Typography>
          </ButtonContained>
          <ButtonContained
            disabled={selectedIndex === null}
            onClick={() =>handleTest(false)}
            aftIcon={<IconNext />}
          >
            <Typography variant={"h5"}>PARTIAL</Typography>
          </ButtonContained>
        </Div>
      </Div>
    );
  };

  if (isError) {
    return <>Error...</>;
  }
  if (isLoading) {
    return <>Loading...</>;
  }
  return (
    <>
      {!tokenIsError && !tokenIsLoading && token && (
        <Ad title={tokenInfo} severity={colorSeverity} close />
      )}
      <Section centered title="Choose your test:">
        <Container maxWidth={"sm"} sx={{ padding: 0, mb: 6 }}>
          <Div
            contentCentered
            sx={{ bgcolor: "transparent", paddingX: 0 }}
            centered
          >
            <GroupFormSelector
              selector={"outline"}
              xs={12}
              sm={6}
              md={4}
              elements={getElements()}
              selectorColor={"primary.main"}
              setIndex={setSelectedIndex}
              selectedIndex={selectedIndex}
            />
          </Div>
        </Container>
      </Section>
      <BottomInfo middleChildren={getInfo()} />
    </>
  );
}
