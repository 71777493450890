import {
  alpha,
  Box,
  Container,
  IconButton,
  Stack,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { Link, NavLink } from "react-router-dom";
import { IconHome } from "../assets/Icons";
import { useStateSelector } from "../hooks/store/useStateSelector";
import StateConsts from "../consts/StateConsts";
import TopInfo from "../pages/quiz/components/TopInfo";
import Img from "./Img";
import ThemeSwitcher from "./ThemeSwitcher";

export default function Navigation() {
  const stateSelector = useStateSelector();
  const theme = useTheme();
  const color = alpha(theme.palette.primary.main, 0.03);
  return (
    <Box
      sx={{
        borderBottom: "1px solid",
        borderColor: alpha(theme.palette.text.primary, 0.1),
        padding: 2,
      }}
    >
      <Container maxWidth={"xl"}>
        <Grid
          container
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs={0}>
            <Img
              width={50}
              link="https://west-wings-aviation.eu/wp-content/uploads/2022/04/west-wings-aviation_LOGO-300x265.png"
            />
          </Grid>

          <Grid item xs={9}>
            {(stateSelector.name === StateConsts.QUIZ_STATE ||
              stateSelector.name === StateConsts.TIMEOUT_STATE ||
              stateSelector.name === StateConsts.SHOW_RESULT_STATE || 
              stateSelector.name === StateConsts.QUIZ_RECAP) && (
                <TopInfo />
              )}
          </Grid>

          <Grid item xs={1} container direction={"row"} spacing={3}>
            <Grid item xs={12} md={6}>
              <ThemeSwitcher />
            </Grid>
            <Grid item xs={12} md={6}>
              <Tooltip title={"Visit our website"}>
                <Link to={"https://www.west-wings-aviation.eu"} target="_blank">
                  <IconButton
                    disableTouchRipple
                    color="primary"
                    edge="start"
                    sx={{
                      border: "1px solid",
                      borderColor: color,
                      borderRadius: 2,
                      backgroundColor: color,
                    }}
                  >
                    <IconHome fontSize="small" />
                  </IconButton>
                </Link>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
