import { useQuiz } from "../../hooks/query/useQuiz";
import {
  selectCurrentQuestion,
  selectCurrentQuestionNo,
  selectQuizTime,
  selectTotalQuestions,
  useQuizId,
} from "../../hooks/store/useQuizSelector";
import { Typography } from "@mui/material";
import ButtonContained from "../../components/ButtonContained";
import Countdown from "./components/Countdown";
import { useDispatch, useSelector } from "react-redux";
import { selectQuestionState } from "../../hooks/store/useQuestionStateSelector";
import QuestionStateConsts from "../../consts/QuestionStateConsts";
import { IconDone, IconNext } from "../../assets/Icons";
import { addUserAnswer, incrementQuestionNo } from "../../store/quiz";
import { setNextState, setVerifyState } from "../../store/questionState";
import { showResultState } from "../../store/state";
import { useState } from "react";
import { IQuestion } from "../../assets/quiz";
import Question from "./components/Question";
import BottomInfo from "./components/BottomInfo";

interface IQuiz {}

export default function Quiz(props: IQuiz) {
  const id = useQuizId();
  const { data: quiz, isLoading, isError } = useQuiz(id);
  const dispatch = useDispatch();
  const time = useSelector(selectQuizTime);
  const noOfQuestions = useSelector(selectTotalQuestions);
  const currentQuestion = useSelector(selectCurrentQuestion);
  const currentQuestionNo = useSelector(selectCurrentQuestionNo);
  const questionState = useSelector(selectQuestionState);

  const [localSelectedIndex, setLocalSelectedIndex] = useState<number | null>(
    null
  );

  const totalQuestions =
    quiz?.payload?.questions && quiz?.payload?.questions.length;

  const getQuestion = (): IQuestion => {
    if (!currentQuestion) {
      return {
        text: "No question available",
        answers: [],
        images: [],
      };
    }

    return {
      text: currentQuestion.text,
      answers: currentQuestion.answers,
      images: currentQuestion.images,
    };
  };

  const handleNext = () => {
    setLocalSelectedIndex(null);
    dispatch(incrementQuestionNo());
    dispatch(setVerifyState());
  };

  const verifyAnswer = () => {
    dispatch(setNextState());
    registerAnswer();
  };

  const handleFinish = () => {
    dispatch(showResultState());
  };

  const registerAnswer = () => {
    if (localSelectedIndex !== null) {
      const question = getQuestion();
      dispatch(
        addUserAnswer([
          {
            ...question.answers[localSelectedIndex],
            userSelectedIndex: localSelectedIndex,
          },
        ])
      );
    }
  }

    const getStateButtons = () => {
      return <>
      {questionState === QuestionStateConsts.VERIFY_STATE ? (
        <ButtonContained
          disabled={localSelectedIndex === null}
          onClick={verifyAnswer}
          aftIcon={<IconNext />}
        >
          <Typography variant={"h5"}>
            {QuestionStateConsts.VERIFY_STATE}
          </Typography>
        </ButtonContained>
      ) : currentQuestionNo === totalQuestions ? (
        <ButtonContained
          disabled={localSelectedIndex === null}
          onClick={handleFinish}
          aftIcon={<IconDone/>}
        >
          <Typography variant={"h5"}>
            {QuestionStateConsts.FINISH_STATE}
          </Typography>
        </ButtonContained>
      ) : (
        <ButtonContained
          disabled={localSelectedIndex === null}
          onClick={handleNext}
          aftIcon={<IconNext/>}
        >
          <Typography variant={"h5"}>
            {QuestionStateConsts.NEXT_STATE}
          </Typography>
        </ButtonContained>
      )}</>
    };

    const getCountdown = () => {
      const totalTime = time * noOfQuestions;
      return <Countdown initialSeconds={totalTime} />;
    };


    if (isLoading) {
      return <>Loading...</>;
    }

    if (isError) {
      return <>Error...</>;
    }
    if (!quiz?.payload) {
      return <>No data</>;
    }

    return (
      <>
        {questionState === QuestionStateConsts.NEXT_STATE ? (
        <Question
          setSelectedIndex={setLocalSelectedIndex}
          question={getQuestion()}
          selectedIndex={localSelectedIndex}
          correctIndex
        />
      ) : (
        <Question
          setSelectedIndex={setLocalSelectedIndex}
          question={getQuestion()}
          selectedIndex={localSelectedIndex}
        />
      )}
        <BottomInfo leftChildren={getStateButtons()} rightChildren={getCountdown()}/>
      </>
    );

}
