import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import Navigation from "./components/Navigation";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useThemeSelector } from "./hooks/store/useThemeSelector";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { dark, light } from "./themes/CustomTheme";
import TokenExpired from "./pages/tokenExpired/TokenExpired";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setStatus } from "./store/authentication";
import { TokenState } from "./types/TokenStates";

function App() {
  const queryClient = new QueryClient();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleTokenExpired = () => {
      dispatch(setStatus(TokenState.EXPIRED));
    };

    document.addEventListener("tokenExpired", handleTokenExpired);

    return () => {
      document.removeEventListener("tokenExpired", handleTokenExpired);
    };
  }, []);

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={useThemeSelector().light ? light : dark}>
          <CssBaseline />
          <Navigation />
          <Router />
          <TokenExpired/>
        </ThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
