import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAnswer, IQuestion } from "../assets/quiz";

interface IQuizNameId {
  id: string;
  name: string;
}
interface IQuizSlice {
  quiz: IQuizNameId;
  noOfQuestionsRequested:null|number;
  questions: IQuestion[];
  questionNo: number;
  userAnswers: IAnswer[];
  time: number;
}

const initialState: IQuizSlice = {
  quiz: { id: "", name: "" },
  noOfQuestionsRequested:null,
  questions: [],
  questionNo: 1,
  userAnswers: [],
  time: 0,
};

const quizSlice = createSlice({
  name: "quizSchema",
  initialState,
  reducers: {
    setQuizNameAndId: (quizSchema, action) => {
      return {
        ...quizSchema,
        quiz: action.payload,
      };
    },
    setQuizTime: (quizSchema, action) => {
      return {
        ...quizSchema,
        time: action.payload,
      };
    },
    setQuestions: (quizSchema, action: PayloadAction<IQuestion[]>) => {
      return {
        ...quizSchema,
        questions: action.payload,
      };
    },

    incrementQuestionNo: (quizSchema) => {
      return {
        ...quizSchema,
        questionNo: quizSchema.questionNo + 1,
      };
    },

    decrementQuestionNo: (quizSchema) => {
      return {
        ...quizSchema,
        questionNo: quizSchema.questionNo - 1,
      };
    },
    setQuestionNo : (quizSchema, action) => {
      return {
        ...quizSchema,
        questionNo: action.payload,
      };
    },

    addUserAnswer: (quizSchema, action) => {
      const updatedUserAnswers = [...quizSchema.userAnswers, ...action.payload];
      return {
        ...quizSchema,
        userAnswers: updatedUserAnswers,
      };
    },
    setNoOfQuestionsRequested: (quizSchema, action) => {
      return {
        ...quizSchema,
        noOfQuestionsRequested: action.payload,
      };
    },

    resetQuiz: () => initialState,
  },
});

export const {
  setQuizNameAndId,
  setQuestions,
  incrementQuestionNo,
  decrementQuestionNo,
  addUserAnswer,
  resetQuiz,
  setQuizTime,
  setQuestionNo,
  setNoOfQuestionsRequested,
} = quizSlice.actions;
export default quizSlice.reducer;
