import { Button, ButtonPropsColorOverrides } from "@mui/material";
import { OverridableStringUnion } from '@mui/types';
interface IButtonContained {
  disabled?: boolean;
  onClick?: () => void;
  children?: JSX.Element | string;
  befIcon?: JSX.Element;
  aftIcon?: JSX.Element;
  color?:OverridableStringUnion<"inherit" | "success" | "info" | "warning" | "error" | "primary" | "secondary", ButtonPropsColorOverrides> | undefined
}
export default function ButtonContained(props: IButtonContained) {
  return (
    <Button
      size={"large"}
      variant="contained"
      color={props.color ?? "primary"}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.befIcon}
      {props.children}
      {props.aftIcon}
    </Button>
  );
}
