import {
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useQuizSelector } from "../../hooks/store/useQuizSelector";
import { resetQuiz, setQuestionNo } from "../../store/quiz";
import { chooseTestState, quizRecap } from "../../store/state";
import Div from "../../components/Div";
import { setVerifyState } from "../../store/questionState";
import { useStateSelector } from "../../hooks/store/useStateSelector";
import StateConsts from "../../consts/StateConsts";
import BottomInfo from "../quiz/components/BottomInfo";
import ButtonContained from "../../components/ButtonContained";

interface IShowResults {}

export default function ShowResults(props: IShowResults) {
  const dispatch = useDispatch();
  const userAnswers = useQuizSelector().userAnswers;
  const questionsLength = useQuizSelector().questions.length;
  const stateSelector = useStateSelector();
  const [promoted, setPromoted] = useState(false);
  const [score, setScore] = useState(() => {
    let good = 0;

    userAnswers.forEach((answer) => {
      if (answer.isCorrect) {
        good++;
      }
    });

    const sc = Math.floor((good / questionsLength) * 100);
    sc >= 75 && setPromoted(true);

    return sc;
  });

    useEffect(() => {
      dispatch(setQuestionNo(userAnswers.length));
    }, [dispatch, userAnswers.length])

  const handleClick = () => {
    dispatch(setVerifyState());
    dispatch(chooseTestState());
    dispatch(resetQuiz());
  };

  const recapQuiz = () => {
    dispatch(quizRecap());
  };

  const getMessage = () => {
    if (score === 100) {
      return "Outstanding!";
    }
    if (score >= 90) {
      return "You nailed it!";
    }
    if (score >= 80) {
      return "Excellent work!";
    }
    if (score >= 75) {
      return "You did it!";
    }

    return "Better luck next time!";
  };

  const getNewQuizButton = () => {
    return (
      <ButtonContained onClick={handleClick}>
        <Typography variant={"h5"}>New Quiz</Typography>
      </ButtonContained>
    );
  };

  const getRecapButton = () => {
    return (
      <ButtonContained onClick={recapQuiz}>
        <Typography variant={"h5"}>See results</Typography>
      </ButtonContained>
    );
  };

  return (
    <>
      <Grid
        container
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item xs={12} md={6}>
          {stateSelector.name === StateConsts.TIMEOUT_STATE && (
            <Typography variant="h5">Your time has expired!</Typography>
          )}
          <Div
            sx={{
              bgcolor: "transparent",
              display: "grid",
              paddingX: 0,
            }}
          >
            <Typography
              color={promoted ? "success.main" : "error.main"}
              variant={"h4"}
            >
              {getMessage()}
            </Typography>
            <Typography
              color={promoted ? "success.main" : "error.main"}
              variant={"h4"}
            >
              You scored {score}%
            </Typography>
          </Div>
        </Grid>
      </Grid>

      <BottomInfo
        leftChildren={getRecapButton()}
        rightChildren={getNewQuizButton()}
      />
    </>
  );
}
