import { useAuthentiactionSelector } from "../../hooks/store/useAuthenticationSelector";
import { TokenState } from "../../types/TokenStates";
import TokenExpiredComponent from "./components/TokenExpiredComponents";

export default function(){
  
const tokenStatus = useAuthentiactionSelector().status;

// Render TokenExpired component if token is expired
if (tokenStatus === TokenState.EXPIRED) {
  return <TokenExpiredComponent />;
}
return <></>
}