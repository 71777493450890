import { useEffect, useState } from "react";
import { getQuiz } from "../../services/v1/quizzesService";
import { setQuestions, setQuizTime } from "../../store/quiz";
import { useDispatch, useSelector } from "react-redux";
import { IQuiz } from "../../assets/quiz";
import { IResponse } from "../../types/ResponseType";
import { selectNoOfQuestionsRequested } from "../store/useQuizSelector";

export const useQuiz = (id: string) => {
  const [data, setData] = useState<IResponse<IQuiz>>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const noOfQuestionsRequested = useSelector(selectNoOfQuestionsRequested);

  useEffect(() => {
    (async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const data = await getQuiz(id, noOfQuestionsRequested);
        setData(data);
        if(data.payload){
          dispatch(setQuestions(data.payload.questions));
          dispatch(setQuizTime(data.payload.timeOnQuestion))
        }
      } catch (err) {
        setIsError(true);
      }
      setIsLoading(false);
    })();
  }, []);

  return {
    data,
    isLoading,
    isError,
  };
};
