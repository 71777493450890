import { Tooltip } from "@mui/material";

interface ITooltipWithIcon {
  icon: JSX.Element;
  info: string;
  placement?:
    | "top"
    | "right"
    | "bottom"
    | "left"
    | "top-start"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | undefined;
}
export default function TooltipWithIcon(props: ITooltipWithIcon) {
  return (
    <Tooltip title={props.info} placement={props.placement}>
      <div>{props.icon}</div>
    </Tooltip>
  );
}
