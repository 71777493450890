import { Button, Container, Typography, alpha, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IconNext } from "../../assets/Icons";
import Ad from "../../components/Ad";
import Div from "../../components/Div";
import GroupFormSelector from "../../components/GroupFormSelector";
import Section from "../../components/Section";
import Widget from "../../components/Widget";
import { setQuizNameAndId } from "../../store/quiz";
import { useAllQuizzes } from "../../hooks/query/useAllQuizzes";
import { useNavigate } from "react-router";
interface IQuizzes {}
export default function Quizzes(props: IQuizzes) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [info, setInfo] = useState("Please select an option to continue!");

  const { data: quizzes, isLoading, isError } = useAllQuizzes();
  useEffect(() => {
    quizzes?.payload &&
      selectedIndex !== null &&
      setInfo(`You selected ${quizzes?.payload[selectedIndex].name}`);
  }, [selectedIndex, quizzes?.payload]);

  const getElements = (): JSX.Element[] => {
    return quizzes?.payload
      ? quizzes?.payload.map((materie, index) => (
          <Div
            key={index}
            width={150}
            height={150}
            contentCentered
            centered
            sx={{ bgcolor: "transparent" }}
          >
            <Widget title={materie.name} direction="column" />
          </Div>
        ))
      : [];
  };

  const handleClick = () => {
    if (quizzes?.payload && selectedIndex !== null) {
      dispatch(
        setQuizNameAndId({
          id: quizzes?.payload[selectedIndex].id,
          name: quizzes?.payload[selectedIndex].name,
        })
      );
      // dispatch(quizState());
      navigate(`/quiz/${quizzes?.payload[selectedIndex].id}`);
    }
  };

  if (isError) {
    return <>Error...</>;
  }
  if (isLoading) {
    return <>Loading...</>;
  }
  return (
    <>
      <Ad title={"Enjoy your free trial!"} severity={"success"} close />
      <Section centered title="Choose your test:">
        <Container maxWidth={"sm"} sx={{ padding: 0, mb: 6 }}>
          <Div
            contentCentered
            sx={{ bgcolor: "transparent", paddingX: 0 }}
            centered
          >
            <GroupFormSelector
              selector={"outline"}
              xs={12}
              sm={6}
              md={4}
              elements={getElements()}
              selectorColor={"primary.main"}
              setIndex={setSelectedIndex}
              selectedIndex={selectedIndex}
            />
          </Div>
        </Container>

        <Div
          contentCentered
          sx={{
            borderTop: "1px solid",
            borderColor: alpha(theme.palette.text.primary, 0.1),
            flexDirection: "column",
            gap: 2,
            bgcolor: "primary",
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
          }}
        >
          <Typography variant={"h6"}>{info}</Typography>

          <Button
            size={"large"}
            variant="contained"
            color="primary"
            disabled={selectedIndex === null}
            onClick={handleClick}
          >
            <Typography variant={"h5"}>START</Typography>
            <IconNext />
          </Button>
        </Div>
      </Section>
    </>
  );
}
