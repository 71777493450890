import { useEffect, useState } from "react";
import { getAllQuizzes } from "../../services/v1/quizzesService";
import { IQuizObj } from "../../types/quizzesTypes";
import { IResponse } from "../../types/ResponseType";

export const useAllQuizzes = () => {
  const [data, setData] = useState<IResponse<IQuizObj[]>>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    (async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const data = await getAllQuizzes();
        setData(data);
      } catch (err) {
        setIsError(true);
      }
      setIsLoading(false);
    })();
  }, []);
  

  return {
    data,
    isLoading,
    isError,
  };
};
