import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Keys } from "../common/Keys";
import { TokenState } from "../types/TokenStates";

interface IAuthenticationSlice {
  token: string | null;
  status:TokenState,
}

const initialState: IAuthenticationSlice = {
  token: localStorage.getItem(Keys.TOKEN),
  status: TokenState.UNKNOWN
};

const authenticationSlice = createSlice({
  name: "authenticationSlice",
  initialState: initialState,
  reducers: {
    setToken: (authenticationSchema, action: PayloadAction<string | null>) => {
      action.payload && localStorage.setItem(Keys.TOKEN, action.payload);
      return {
        ...authenticationSchema,
        token: action.payload,
      };
    },
    setStatus: (authenticationSchema, action: PayloadAction<TokenState>) => {
      return {
        ...authenticationSchema,
        status: action.payload,
      };
    },
 
    logOut: () => {
      localStorage.removeItem(Keys.TOKEN);
      return initialState;
    },
  },
});
export const { setToken, logOut, setStatus } =
  authenticationSlice.actions;
export default authenticationSlice.reducer;
