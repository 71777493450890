import { createSlice } from "@reduxjs/toolkit";
import StateConsts from "../consts/StateConsts";
import { Keys } from "../common/Keys";

const isToken = () => {
  return localStorage.getItem(Keys.TOKEN) != null;
}
const stateSlice = createSlice({
  name: "stateSchema",
  initialState: {
    // name: StateConsts.CHOOSE_TEST_STATE, //AUTHENTIFICATON_STATE
    name: isToken() ? StateConsts.CHOOSE_TEST_STATE :StateConsts.AUTHENTIFICATON_STATE, //AUTHENTIFICATON_STATE
  },
  reducers: {
    authentificationState: (stateSchema) => {
      stateSchema.name = StateConsts.AUTHENTIFICATON_STATE;
    },
    chooseTestState: (stateSchema) => {
      stateSchema.name = StateConsts.CHOOSE_TEST_STATE;
    },
    quizState: (stateSchema) => {
      stateSchema.name = StateConsts.QUIZ_STATE;
    },
    showResultState: (stateSchema) => {
      stateSchema.name = StateConsts.SHOW_RESULT_STATE;
    },
    timeoutState: (stateSchema) => {
      stateSchema.name = StateConsts.TIMEOUT_STATE;
    },
    quizRecap: (stateSchema) => {
      stateSchema.name = StateConsts.QUIZ_RECAP;
    }
  },
});
export const {
  authentificationState,
  chooseTestState,
  quizState,
  showResultState,
  timeoutState,
  quizRecap,
} = stateSlice.actions;
export default stateSlice.reducer;
