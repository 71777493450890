import { Box, Stack, Typography } from "@mui/material";
import Title from "./Title";

type ISection = {
  title?: string;
  children?: any;
  centered?: boolean;
  centerText?: boolean;
};
export default function Section(props: ISection) {
  return (
    <Box my={3}>
      <Stack
        spacing={2}
        display={props.centered ? "grid" : "block"}
        justifyContent={props.centered ? "center" : ""}
      >
        <Title align={props.centered || props.centerText ? "center" : "left"}>
          {props.title}
        </Title>
        <Box>{props.children}</Box>
      </Stack>
    </Box>
  );
}
