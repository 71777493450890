import { Container } from "@mui/material";
import { Outlet } from "react-router";
import Div from "../Div";

export default function ContentLayout(props: any) {
  return (
    <Container maxWidth={"lg"} sx={{ marginBottom: 14, marginTop: 10 }}>
      {/* <>{props.children}</> */}

      <Outlet />
    </Container>
  );
}
