import { useState } from "react";
import { isValid } from "../../services/v1/tokenService";
import { Keys } from "../../common/Keys";
import { IToken } from "../../types/TokenType";
import { IResponse } from "../../types/ResponseType";

export const useToken = () => {
  const [token, setToken] = useState<IResponse<IToken>>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const checkIsValidToken = async (token: string) => {
    setIsLoading(true);
    setIsError(false);
    try {
      const data = await isValid(token);
      setToken(data);
      if(data.payload && data.payload.isValid){
        localStorage.setItem(Keys.TOKEN, data.payload.token)
      }
    } catch (err) {
      setIsError(true);
    }
    setIsLoading(false);
  };


  return {
    token,
    isLoading,
    isError,
    checkIsValidToken,
  };
};
