import { useState, FormEvent, useEffect } from "react";
import { Stack, Typography, TextField, Button } from "@mui/material";
import Div from "../../components/Div";
import { useLogIn } from "../../hooks/query/useLogIn";
import { useTokenCreate } from "../../hooks/query/useTokenCreate";
import { TokenCreate } from "../../types/TokenType";
import { TokenState } from "../../types/TokenStates";
import TokenModal from "./components/TokenModal";
import Ad from "../../components/Ad";

export default function CreateToken() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [daysValidity, setDaysValidity] = useState<number>(1);
  const [validFrom, setValidFrom] = useState<Date | null>(null);
  const [validUntil, setValidUntil] = useState<Date | null>(null);

  const {
    data: loginData,
    isLoading: loginLoading,
    isError: loginError,
    logIn,
  } = useLogIn();
  const {
    data: tokenData,
    isLoading: tokenLoading,
    isError: tokenError,
    create: createToken,
  } = useTokenCreate();

  const [shouldLogIn, setShouldLogIn] = useState(false);
  const [shouldCreateToken, setShouldCreateToken] = useState(false);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (isFormValid()) {
      setShouldLogIn(true); // Set flag to trigger login
    }
  };

  useEffect(() => {
    if (shouldLogIn && !loginData?.payload?.accessToken) {
      (async () => {
        try {
          await logIn({ email, password });
          setShouldCreateToken(true);
        } catch (error) {
        }
      })();
      setShouldLogIn(false);
    }
  }, [shouldLogIn, loginData, logIn, email, password]);

  useEffect(() => {
    if (shouldCreateToken && loginData?.payload?.accessToken) {
      const tokenCreateParams: TokenCreate = {
        daysValidity,
        validFrom,
        validUntil,
      };

      (async () => {
        try {
          loginData.payload &&
            (await createToken(
              loginData?.payload.accessToken,
              tokenCreateParams
            ));
        } catch (error) {
          console.error("Error creating token:", error);
        }
      })();
      setShouldCreateToken(false); // Reset flag
    }
  }, [shouldCreateToken, loginData, createToken]);

  const isFormValid = () =>
    email.trim().length > 0 &&
    email.includes("@") &&
    email.includes(".") &&
    email.lastIndexOf(".") > email.lastIndexOf("@") &&
    email.lastIndexOf(".") != email.length - 1 &&
    password.trim().length > 0 &&
    daysValidity > 0;

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap={3}>
        <Div centered sx={{ backgroundColor: "transparent" }}>
          <Typography variant="h5">Create token</Typography>
        </Div>
        <Stack gap={2}>
          <TextField
            placeholder="Email"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            placeholder="Password"
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
          inputProps={{ min: "1" }}
            placeholder="Validity"
            type="number"
            required
            value={daysValidity}
            onChange={(e) => setDaysValidity(Number(e.target.value))}
          />
          <Typography variant="body2" color="info">
            *Validity* represents the duration for which the token remains
            valid.
          </Typography>
        </Stack>
        {loginLoading || tokenLoading ? (
          <Button type="submit" variant="contained" disabled>
            Loading...
          </Button>
        ) : loginError || tokenError ? (
          <>
            <Ad
              title="ERROR"
              severity="error"
              desc={"Invalid email or password. Please try again."}
            />
            <Button
              type="submit"
              variant="contained"
              disabled={!isFormValid() || loginLoading || tokenLoading}
            >
              Create token
            </Button>
          </>
        ) : tokenData?.payload && loginData?.payload ? (
          <Ad
            title="SUCCESS"
            severity="success"
            desc="Token created successfully!"
          />
        ) : (
          <Button
            type="submit"
            variant="contained"
            disabled={!isFormValid() || loginLoading || tokenLoading}
          >
            Create token
          </Button>
        )}
      </Stack>
      {tokenData?.payload?.status === TokenState.VALID && (
        <TokenModal
          token={tokenData.payload.token}
          daysValidity={tokenData.payload.daysValidity}
        />
      )}
    </form>
  );
}
