import { Stack, Typography, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Div from "./Div";

interface IModalBackground{
    children?:any
}
export default function ModalBackground(props:IModalBackground) {


  return (
    <Div
      sx={{
        background: "rgba(0,0, 0, 0.7)",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Div>
        {props.children}
      </Div>
    </Div>
  );
}

