import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUserAnswers,
  selectQuestions,
} from "../../hooks/store/useQuizSelector";
import Question from "../quiz/components/Question";
import { IQuestion } from "../../assets/quiz";
import BottomInfo from "../quiz/components/BottomInfo";
import ButtonContained from "../../components/ButtonContained";
import { IconClose, IconNext, IconPrev } from "../../assets/Icons";
import { Stack, Typography } from "@mui/material";
import QuestionStateConsts from "../../consts/QuestionStateConsts";
import Ad from "../../components/Ad";
import { showResultState } from "../../store/state";
import { setQuestionNo } from "../../store/quiz";
import Div from "../../components/Div";

export default function QuizRecap() {
  const userAnswers = useSelector(selectUserAnswers);
  const questions = useSelector(selectQuestions);
  const dispatch = useDispatch();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [localSelectedIndex, setLocalSelectedIndex] = useState<number | null>(
    userAnswers[currentQuestionIndex]?.userSelectedIndex
  );
  const [isResponse, setIsResponse] = useState<boolean>(
    userAnswers[currentQuestionIndex]?.userSelectedIndex !== undefined
  );

  const totalQuestions = questions.length;

  useEffect(() => {
    setIsResponse(
      userAnswers[currentQuestionIndex]?.userSelectedIndex !== undefined
    );
    dispatch(setQuestionNo(currentQuestionIndex + 1));
  }, [currentQuestionIndex, dispatch, userAnswers]);

  const getQuestion = (): IQuestion => {
    return {
      text: questions[currentQuestionIndex].text,
      answers: questions[currentQuestionIndex].answers,
      images: questions[currentQuestionIndex].images,
    };
  };

  const handleNext = () => {
    if (currentQuestionIndex < totalQuestions - 1) {
      setLocalSelectedIndex(
        userAnswers[currentQuestionIndex + 1]?.userSelectedIndex
      );
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentQuestionIndex > 0) {
      setLocalSelectedIndex(
        userAnswers[currentQuestionIndex - 1]?.userSelectedIndex
      );
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const getNavigationButtons = () => {
    return (
      <Stack direction={"row"} gap={1}>
      <ButtonContained
        onClick={handlePrev}
        disabled={currentQuestionIndex === 0}
      >
        <IconPrev />
      </ButtonContained>
      <ButtonContained
        onClick={handleNext}
        disabled={currentQuestionIndex + 1 === questions.length}
      >
        <IconNext />
      </ButtonContained>
      </Stack>
    );
  };

  const exit = () => {
    dispatch(showResultState());
  };

  const getExitButton = () => {
    return <ButtonContained color="error" onClick={exit}>
        <IconClose/>
    </ButtonContained>;
  };

  return (
    <>
      {!isResponse && (
        <Ad
          title={"You didn't provide an answer to this question."}
          severity="error"
        />
      )}
      <Question
        setSelectedIndex={setLocalSelectedIndex}
        question={getQuestion()}
        selectedIndex={localSelectedIndex}
        correctIndex
      />
      <BottomInfo
        leftChildren={getNavigationButtons()}
        rightChildren={getExitButton()}
      />
    </>
  );
}
