import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Keys } from "../../common/Keys";

export const useAuthentiactionSelector = () => {
    const state = useSelector((state: RootState) => state.entities.authentication);
    return { token: state.token, status: state.status };
  };
  
  export const useIsAuthenticate = () => {
    const token = localStorage.getItem(Keys.TOKEN);
    return token != null;
  };
  