import { SxProps, Theme, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { timeoutState } from '../../../store/state';

interface ICountdown{
    initialSeconds:number;
}

export default function Countdown(props:ICountdown){
    const [seconds, setSeconds] = useState(props.initialSeconds);
    const [pulseColor, setPulseColor] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const countdownInterval = setInterval(() => {
          setSeconds((prevSeconds) => {
            if (prevSeconds === 0) {
              clearInterval(countdownInterval);
              dispatch(timeoutState());
              return 0;
            }
            return prevSeconds - 1;
          });

          seconds < 5 * 60 && setPulseColor((prevColor) => !prevColor);
        }, 1000);
    
        return () => clearInterval(countdownInterval); // Cleanup interval on component unmount
    
      }, [props.initialSeconds, dispatch, seconds]);



      const getTime = () => {
        const minutes = Math.floor(seconds / 60);
        const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      
        const remainingSeconds = seconds % 60;
        const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
      
        return `${formattedMinutes}:${formattedSeconds}`;
      };

      const pulseStyle: SxProps<Theme> = {
        color: pulseColor ? 'error.main' : 'inherit',
        fontWeight:"bold",
      };
      

    return <>
   <Typography variant='h5' sx={pulseStyle}>{getTime()}</Typography>
    </>
}