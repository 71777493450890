import { Keys } from "../../common/Keys";
import axiosInstance from "./axiosInstance";
import { IResponse } from "../../types/ResponseType";
import { IQuiz } from "../../assets/quiz";
import { IQuizObj } from "../../types/quizzesTypes";




const token =localStorage.getItem(Keys.TOKEN);
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;



export const getAllQuizzes = async () => {
  const { data } = await axiosInstance.get<IResponse<IQuizObj[]>>(`/quizzes`);
  return data;
};

export const getQuiz = async (id: string, questions: null | number) => {
  const { data } = await axiosInstance.get<IResponse<IQuiz>>(
    `/quizzes/${id}/questions`,
    {
      params: questions !== null ? { questionLimit: questions } : null,
    }
  );

  return data;
};


export const getQuizAllQuestions = async (id: string) => {
  const { data } = await axiosInstance.get<IResponse<IQuiz>>(
    `quizzes/${id}/summary`
    // {
    //   params: {
    //     questionLimit: 8,
    //   },
    // }
  );
  return data;
};