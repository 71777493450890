import { IResponse } from "../../types/ResponseType";
import { IToken, TokenCreate, TokenResponse } from "../../types/TokenType";
import axiosInstance from "./axiosInstance";




export const isValid = async (token: string) => {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const { data } = await axiosInstance.get<IResponse<IToken>>(`/tokens/is-valid`);
    return data;
  };

  export const createToken = async (accessToken: string, params: TokenCreate) => {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    const { data } = await axiosInstance.post<IResponse<TokenResponse>>(`/tokens`, params);
    return data;
  };
  