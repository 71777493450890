import { Stack, Typography, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Ad from "../../../components/Ad";
import Div from "../../../components/Div";
import { authentificationState } from "../../../store/state";
import ModalBackground from "../../../components/ModalBackground";

export default function TokenExpiredComponent() {
  const dispatch = useDispatch();
  const [seconds, setSeconds] = useState(5);
  (() => {
    setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);
  })();

  useEffect(() => {
    if (seconds <= 0) {
      handleClick();
    }
  }, [seconds]);

  const handleClick = () => {
    dispatch(authentificationState());
    window.location.href = "";
  };

  return (
    <ModalBackground>
      <Stack spacing={1}>
        <Ad title="Your token has expired." severity="warning"></Ad>

        <Typography>
          You will be redirected to the homepage in {seconds} seconds.
        </Typography>
        <Button onClick={handleClick}> Redirect Home</Button>
      </Stack>
    </ModalBackground>
  );
}
