import Div from "./Div";

interface IImage {
  link: string;
  width?: number | string;
  height?: number | string;
  variant?: "fill" | "contain" | "cover" | "none" | "scale-down";
  rounderCorner?: number;
  centered?: boolean;
}
export default function Image(props: IImage) {
  return (
    <Div
      centered={props.centered}
      width={props.width}
      height={props.height}
      sx={{
        bgcolor: "transparent",
        overflow: "hidden",
        padding: 0,
        borderRadius: 0,
      }}
    >
      <img
        style={{
          borderRadius: props.rounderCorner,
          width: "100%",
          height: "100%",
          objectFit: props.variant ? props.variant : "fill",
        }}
        src={props.link}
      />
    </Div>
  );
}
