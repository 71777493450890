import { Grid } from "@mui/material";
import { IQuestion } from "../../../assets/quiz";
import Div from "../../../components/Div";
import Image from "../../../components/Img";
import Section from "../../../components/Section";
import Answer from "./Answer";

interface IQuestionComponent {
  question: IQuestion;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number | null>>;
  selectedIndex: null | number;
  correctIndex?: boolean;
}

export default function Question(props: IQuestionComponent) {
  const correctIndex = props.question.answers.findIndex(
    (question) => question.isCorrect === true
  );
  const getAnswers = (): JSX.Element[] => {
    return props.question.answers.map((answer, index) => (
      <Answer
        key={index}
        number={String.fromCharCode(65 + index)}
        label={answer.text}
        index={index}
        setSelectedIndex={props.setSelectedIndex}
        correctIndex={props.correctIndex === true ? correctIndex : undefined}
        selected={props.selectedIndex}
      />
    ));
  };

  return (
    <>
      <Section title={props.question.text} centerText>
        <Grid
          direction={"row"}
          container
          spacing={1}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={12} md={6}>
            <Div
              sx={{
                bgcolor: "transparent",
                gap: 4,
                display: "grid",
                paddingX: 0,
              }}
            >
              {getAnswers()}
            </Div>
          </Grid>
          {props.question.images !== null &&
            props.question.images.length !== 0 && (
              <Grid item xs={12} md={6}>
                {props.question.images.map((image, key) => {
                  return (
                    <Grid key={key}>
                      <Div
                        sx={{
                          paddingX: 0,
                          bgcolor: "transparent",
                        }}
                      >
                        <Image
                          centered
                          width={"100%"}
                          rounderCorner={12}
                          variant={"fill"}
                          link={image.url}
                        />
                      </Div>
                    </Grid>
                  );
                })}
              </Grid>
            )}
        </Grid>
      </Section>
    </>
  );
}
